import React, { useRef } from 'react'
import { navigate } from 'gatsby'
import { watch, local } from '../model'

import Layout from '../components/layout'
import SEO from '../components/seo'

import './index.css'

const DashboardPage = () => {
  const inputRef = useRef()
  const user = watch(local.user)

  if (!user) {
    if (typeof window !== 'undefined') navigate('/')
    return null
  }

  const onCreateOrg = (event: React.MouseEvent) => {
    event.preventDefault()
  }

  return (
    <Layout>
      <SEO title="Lona" />
      <h1>Welcome {user.username}</h1>
      <h2>Organisations</h2>
      <ul>
        {user.organisations.map(org => (
          <li key={org.id}>{org.name}</li>
        ))}
      </ul>
      <input ref={inputRef} placeholder="Organisation's name" />
      <button onClick={onCreateOrg}>Create organisation</button>
    </Layout>
  )
}

export default DashboardPage
